import * as React from 'react';
// import Head from 'next/head';
import { AppProps } from 'next/app';
import { CacheProvider, EmotionCache } from '@emotion/react';

import { useRouter } from 'next/router';

import { pageview } from '../utils/gtm';
import createEmotionCache from '../createEmotionCache';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const router = useRouter();

  React.useEffect(() => {
    if (router) {
      let isInitialLoad = true; // Flag to track initial load

      const handleRouteChange = () => {
        // Use router.pathname directly to get the path of the current route
        pageview(router.asPath); // Pass the pathname directly to your pageview function
        setTimeout(() => {
          if (router.asPath.includes('#')) {
            const hash = router.asPath.split('#')[1];
            const element = document.getElementById(hash);
            if (element) {
              const header = document.querySelector('[class*="Header-root"]');

              const offset = 48 + (header?.clientHeight || 0);

              window.scrollTo({
                top: element.getBoundingClientRect().top + window.pageYOffset - offset,
                behavior: 'smooth'
              });
            }
          }
        }, 300);
      };

      // Directly call handleRouteChange for the initial load if it hasn't been called.
      if (isInitialLoad) {
        handleRouteChange();
        isInitialLoad = false; // Set flag to false after handling initial load
      } else {
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
          router.events.off('routeChangeComplete', handleRouteChange);
        };
      }
    }
  }, [router]);

  return (
    <CacheProvider value={emotionCache}>
      <Component {...pageProps} />
    </CacheProvider>
  );
}
